<template>
  <div @mouseleave="hideSubNavi" class="navi-mobile fixed w-screen bottom-0  z-10 ">
    <transition-group name="slide-fade">
      <navi-mobile-sub :key="1" :links="this.menu.schreibtisch" v-if="activeSubNavi === 'schreibtisch'"></navi-mobile-sub>
      <navi-mobile-sub :key="2" :links="this.menu.lerninhalte" v-if="activeSubNavi === 'lerninhalte'">
        <li>
          <button @click.prevent="" class="navi-mobile__sub-button flex bg-primary-800 justify-center ">
            <webcode-navigator></webcode-navigator>
          </button>
        </li>
      </navi-mobile-sub>
      <navi-mobile-sub :key="3" :links="this.menu.werkzeuge" v-if="activeSubNavi === 'werkzeuge'"></navi-mobile-sub>
      <navi-mobile-sub :key="4" :links="this.menu.user" v-if="activeSubNavi === 'user'">
        <li v-if="UserData">
          <form action="/logout" method="post" class="navi-mobile__sub-button">
            <hermeneus-icon name="hi-logout" width="32" height="32" color="current"></hermeneus-icon>
            <input type="hidden" name="_token" value="{{csrf}}">
            <button type="submit" name="ausloggen" value="ausloggen" class="ml-2">Ausloggen</button>
          </form>
        </li>
      </navi-mobile-sub>

      <navi-mobile-hamburger :key="5" :links="this.menu" v-if="activeSubNavi === 'hamburger'"></navi-mobile-hamburger>

    </transition-group>
    <ul class="flex flex-row  box-shadow-wide bg-grey-200">

      <li class="flex flex-row overflow-hidden flex-1 justify-center">
        <button
            @click.prevent="toggleSubNavi($event, 'schreibtisch')"
            @mouseover="toggleSubNavi('schreibtisch')"
            class="p-3 w-full flex justify-center text-primary-600 flex">
          <hermeneus-icon name="hi-desk" width="32" height="32" color="current"></hermeneus-icon>
          <div class="hidden sm:visible">Schreibtisch</div>
        </button>
      </li>
      <li class="flex flex-row overflow-hidden flex-1 justify-center">
        <button
            @click.prevent="toggleSubNavi($event, 'lerninhalte')"
            @mouseover="toggleSubNavi('lerninhalte')"
            class="p-3 w-full flex justify-center text-primary-600">
          <hermeneus-icon name="hi-hermeneus" width="32" height="32" color="current"></hermeneus-icon>
          <div class="hidden sm:visible">Lerninhalte</div>
        </button>
      </li>
      <li class="flex flex-row overflow-hidden flex-1 justify-center">
        <button
            @click.prevent="toggleSubNavi($event, 'werkzeuge')"
            @mouseover="toggleSubNavi('werkzeuge')"
            class="p-3 w-full flex justify-center text-primary-600">
          <hermeneus-icon name="hi-werkzeuge" width="32" height="32" color="current"></hermeneus-icon>
          <div class="hidden sm:visible">Werkzeuge</div>
        </button>
      </li>
      <li class="flex flex-row overflow-hidden flex-1 justify-center">
        <button
            @click.prevent="toggleSubNavi($event, 'ressourcen')"
            @mouseover="toggleSubNavi('ressourcen')"
            class="p-3 w-full flex justify-center text-primary-600">
          <hermeneus-icon name="hi-ressourcen" width="32" height="32" color="current"></hermeneus-icon>
          <div class="hidden sm:visible">Ressourcen</div>
        </button>
      </li>
      <li class="flex flex-row overflow-hidden flex-1 justify-center">
        <button
            @click.prevent="toggleSubNavi($event, 'user')"
            @mouseover="toggleSubNavi('user')"
            class="p-3 w-full flex justify-center text-primary-600">
          <hermeneus-icon name="hi-user" width="32" height="32" color="current"></hermeneus-icon>
          <div class="hidden sm:visible">Benutzerbereich</div>
        </button>
      </li>
      <li class="flex flex-row overflow-hidden flex-1 justify-center">
        <button
            @click.prevent="toggleSubNavi($event, 'hamburger')"
            @mouseover="toggleSubNavi('hamburger')"
            class="p-3 w-full flex justify-center text-primary-600">
          <hermeneus-icon name="hi-hamburger-light" width="32" height="32" color="current"></hermeneus-icon>
          <div class="hidden sm:visible">Ganzes Menü</div>
        </button>
      </li>
    </ul>

  </div>
</template>

<script>

import NaviBase from "./NaviBase.vue"
import NaviMobileSub from "./NaviMobileSub.vue"
import NaviMobileHamburger from "./NaviMobileHamburger.vue"

export default {
  name: "navi-mobile",
  extends: NaviBase,
  components: {
    NaviMobileHamburger,
    "navi-mobile-sub": NaviMobileSub
  },
  data: function () {
    return {
      activeSubNavi: null,
    }
  },

}
</script>

<style lang="scss" scoped>

/**
* Ohne diese Eigenschaften verschwindet das mobile Menü beim Scrollen
 */
.navi-mobile {
  -webkit-transform: translateZ(0);
  bottom: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-enter-from{
  opacity: 0;
  height: 0;
}

</style>